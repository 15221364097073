<template>
  <ConfiguracionInicialComponent />
</template>

<script>
export default {
  components: {
    ConfiguracionInicialComponent: () =>
      import(
        "../../components/FolderEnterprise/ConfiguracionInicialComponent.vue"
      ),
  },
};
</script>

<style></style>
